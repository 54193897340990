<template>
  <div class="invitation-desc">
    <div class="btn-wrapper">
      <van-button
        block
        round
        color="linear-gradient(to right, #ff6034, #ee0a24)"
        @click="getInvitationReward"
        >领取推广奖励</van-button
      >
    </div>
    <div>
      <p><span>推广有奖励，推广的玩家在游戏中的充值，可以兑换大量道具</span></p>
      <p></p>
      <p><span>推广奖励说明正在更新中...</span></p>
      <!-- <section class="_editor">
        <section class="tool-border">
          <section></section>
          <section></section>
          <section></section>
          <section></section>
        </section>
        <p>
          <strong
            ><span style="font-size: 15px;">玩家累计推广积分奖励</span></strong
          >
        </p>
        <p>
          <span style="font-size: 15px;"><br /></span>
        </p>
        <p>
          <span style="font-size: 15px; color: #ff0000;"
            >(玩家的推广邀请码 推荐绑定的新注册玩家每充值1元为1积分)</span
          >
        </p>
        <p>
          <span style="font-size: 15px;"><br /></span>
        </p>
        <p>
          <span style="font-size: 15px;">累积推广积分100 奖励4万绑定元宝</span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <span style="font-size: 15px;"
            >累积推广积分500 再奖励6万绑定元宝</span
          >
        </p>
        <p>
          <br />
        </p>
        <p>
          <span style="font-size: 15px;"
            >累积推广积分1000 再奖励10万绑定元宝</span
          >
        </p>
        <p>
          <br />
        </p>
        <p>
          <span style="font-size: 15px;"
            >累积推广积分2000 再奖励20万绑定元宝</span
          >
        </p>
        <p>
          <br />
        </p>
        <p>
          <span style="font-size: 15px;"
            >累积推广积分5000 再奖励55万绑定元宝</span
          >
        </p>
        <p>
          <br />
        </p>
        <p>
          <span style="font-size: 15px;"
            >累积推广积分8000 再奖励80万绑定元宝</span
          >
        </p>
        <p>
          <br />
        </p>
        <p>
          <span style="font-size: 15px;"
            >累积推广积分10000 再奖励115万绑定元宝</span
          >
        </p>
        <p>
          <br />
        </p>
        <p>
          <span style="font-size: 15px;"
            >累积推广积分15000奖励龙魂元神精魄*5/玄凤元神精魄*5/宝石提升符*10/暗器修炼石*100/妖虎元神精魄*5/</span
          >
        </p>
        <p>
          <br />
        </p>
        <p>
          <span style="font-size: 15px;"
            >累积推广积分20000龙魂元神精魄*10/玄凤元神精魄*10/重楼之锋碎片*1
            /重楼之鳞碎片*1/妖虎元神精魄*10/</span
          >
        </p>
        <p>
          <br />
        </p>
        <p>
          <span style="font-size: 15px;"
            >累积推广积分30000龙魂元神精魄*15/玄凤元神精魄*15/重楼之锋碎片/暗器修炼石*100/妖虎元神精魄*5/</span
          >
        </p>
        <p>
          <br />
        </p>
        <p>
          <span style="font-size: 15px;"
            >累积推广积分30000龙魂元神精魄*15 /玄凤元神精魄*15/ 重楼之锋碎片*1
            /重楼之鳞碎片*1/妖虎元神精魄*15/</span
          >
        </p>
        <p>
          <br />
        </p>
        <p>
          <span style="font-size: 15px;"
            >累积推广积分40000龙魂元神精魄*20/玄凤元神精魄*20/重楼之锋碎片*2/重楼之鳞碎片*2/妖虎元神精魄*20/</span
          >
        </p>
        <p>
          <br />
        </p>
        <p>
          <span style="font-size: 15px;"
            >累积推广积分50000龙魂元神精魄*25/玄凤元神精魄*25/重楼之锋碎片*2
            /重楼之鳞碎片*2/妖虎元神精魄*25/</span
          >
        </p>
        <p>
          <br />
        </p>
        <p>
          <span style="font-size: 15px;"
            >累积推广积分100000 龙魂元神精魄*50/玄凤元神精魄*50/重楼之锋碎片*2
            /重楼之鳞碎片*2/妖虎元神精魄*50 /</span
          >
        </p>
        <p>
          <br />
        </p>
        <p>
          <span style="font-size: 15px;"
            >累积推广积分200000 龙魂元神精魄*100/玄凤元神精魄*100/重楼之华*1
            /重楼之锋*1/妖虎元神精魄*100/</span
          >
        </p>
        <p>
          <br />
        </p>
      </section> -->
    </div>
  </div>
</template>
<script>
import { Button } from "vant";
import { tgUrl } from "@/config/settings";

export default {
  name: "InvitationDesc",
  metaInfo() {
    return {
      title: "推广奖励介绍"
    };
  },
  components: {
    "van-button": Button
  },
  methods: {
    getInvitationReward() {
      window.location.href = tgUrl;
    }
  }
};
</script>
<style lang="less" scoped>
.invitation-desc {
  padding: 0 20px;
}

.btn-wrapper {
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  bottom: 0;
  left: 0;
  padding: 10px 20px;
}
</style>
